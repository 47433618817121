import React from "react"

/* Components */
import { Flex, Box } from "rebass"
import Layout from "../../components/layout"
import ContentTemplate from "../../components/contentTemplate"
import HeaderPara from "../../components/headPara"
import CaptionedImage from "../../components/captionedImage"
import PageNav from "../../components/pageNav"
import Divider from "../../components/divider"

/* Images */
import mainImage from "../../images/anywhr-tailor-made-trips/hero.png"
import mainImageAlt from "../../images/anywhr-tailor-made-trips/hero-loading.png"
import HIW from "../../images/anywhr-tailor-made-trips/homepage-how-it-works.png"
import dialog from "../../images/anywhr-tailor-made-trips/trip-type-dialog.png"
// import userTestReport from "../../images/anywhr-tailor-made-trips/user-test-report.png"
import userflows from "../../images/anywhr-tailor-made-trips/userflows.png"
import actSightsIteration from "../../images/anywhr-tailor-made-trips/act-sights-iteration.png"
import buttonIteration from "../../images/anywhr-tailor-made-trips/button-iteration.png"
import tripRequestUI from "../../images/anywhr-tailor-made-trips/trip-request.png"

const AnywhrTailorMadeTripsPage = () => (
  <Layout>
    <ContentTemplate
      title={" Tailor-Made Trips: Greater Flexibility for Anywhr's Travellers"}
      subtitle={"Anywhr's new direction in trip planning"}
      year={"2019"}
      tags={"UX · UI"}
      mainImage={mainImage}
      mainImageAlt={mainImageAlt}
      responsibilities={"UX, UI"}
      team={
        "Product Manager - Felix Tan, Engineering - Rahul Gupta & Dhruv Jain"
      }
      // stakeholders={
      //   "Marketing - Zelia Leong, Trips & Operations - Summer Lim & Lian Hui Ee"
      // }
      duration={"1.5 months"}
    >
      <HeaderPara>
        <p className="subtitle">
          {
            "After running a 5-day design sprint company stakeholders, we validated that expanding the product in terms of the type of travel experience Anywhr provides is worth pursuing to expand our market reach. We started offering ‘Tailor-Made’ trips as a counterpart to the original ‘Surprise’ trips. Travellers can opt for having more control over their travel itinerary with the Trip Curator’s help in planning and booking their trip."
          }
        </p>
      </HeaderPara>
      <Divider />
      <HeaderPara header={"Quickly gaining insights with an chatbot"}>
        <p>
          {
            "As we knew that the engineers needed a longer time to complete the new user flow on the web app, we decided to launch the MVP as an Intercom bot as this was the fastest way (that required the least engineering effort and time) we could gain insights on how to structure the operations for planning Tailor-Made trips to inform design decisions. The Intercom bot was easily modifiable by the Trip Curators as they could easily refine and prioritise the questions they ask travellers."
          }
        </p>
      </HeaderPara>
      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1 / 2]} pr={[0, 0, 2]}>
          <CaptionedImage
            imageSrc={HIW}
            caption={
              "This replaced the How It Works section on the homepage to reflect Anywhr's new offering."
            }
          />
        </Box>
        <Box width={[1, 1, 1 / 2]} pl={[0, 0, 2]}>
          <CaptionedImage
            imageSrc={dialog}
            caption={
              "At the points where the user would have started the trip customisation user flow, this dialog now appears."
            }
          />
        </Box>
      </Flex>
      <HeaderPara header={"Educating users on the new product option"}>
        <p>
          {
            "As most users would have already known Anywhr as a trip planning company specialising in surprise trips and less-known destinations, we need to introduce the Tailor-Made trip to users. I identified places in the web app and points in the original user flows where instead of heading to the original Trip Customisation user flow, there would be an interface that contrasted the two types of trips the user can choose from."
          }
        </p>
        <CaptionedImage
          imageSrc={userflows}
          caption={
            "Simplified user flows showing the change after implementing the Tailor-Made trip user flows."
          }
        />
      </HeaderPara>
      <HeaderPara
        header={"Integrating the new user flows with the existing system"}
      >
        <p>
          {
            "Together with the product manager, I brainstormed on integrating this new user flow into the current trip customisation user flow, but it was too different in the types of information/data obtained. Feedback from engineering also supported keeping the two flows separate as it will take much more effort to change the existing database structure for the existing "
          }
          <code>Surprise Trip</code>
          {" to accommodate the "}
          <code>Tailor-Made Trip</code>
          {" if they were to be recorded in a single database table."}
        </p>
      </HeaderPara>
      <HeaderPara
        header={"Reducing users' time and effort to get their trip planned"}
      >
        <p>
          {
            "With the feedback from Trip Curators who handled the trip planning operations since the implementation of the MVP, it was easy to rate the importance of each question. As it was logistically difficult to plan a large number of trips, Trip Curators needed to prioritise the trips based on budget and travel dates while users often gave ambiguous answers to questions such as their travel dates and destination. Such ambiguity always led to more back-and-forth conversation which increases the probability of the user losing interest in Anywhr’s service as time goes on. This indicated a need to reduce open-ended answers by providing categorised options."
          }
        </p>
      </HeaderPara>
      <CaptionedImage
        imageSrc={actSightsIteration}
        caption={
          "From left to right: the earliest iteration to final design. Through multiple iterations, I realised that users don't need the search bar as they wouldn't know what options there were. All users instinctively scrolled down to view the available options."
        }
      />
      <HeaderPara
        header={
          "Iteratively improving the usability of questions for users to provide concise yet accurate trip preferences"
        }
      >
        <p>
          {
            "As the intended user flow directly replaced the parts where the Intercom bot was implemented, I designed usability tests that focused on tasks within the trip request user flow such as changing from specific trip dates to a relative travel period and the indication of specific activity interests."
          }
        </p>
        <CaptionedImage
          imageSrc={buttonIteration}
          caption={
            "On the left, the button that leads to the page for relative travel dates is too close to the illustration at the bottom hence most users missed it during usability tests. The button interface on the right worked much better."
          }
        />
      </HeaderPara>
      <HeaderPara>
        <p>
          {
            "Here is one of the iterations that was used for user testing. For the user tests, users were given a specific context for which they had to complete a Trip Request for."
          }
        </p>
        <Box>
          <iframe
            title="Figma Prototype - Anywhr Tailor Made Trip Request"
            style={{ border: "none" }}
            width="100%"
            height="600"
            src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FI4Gv8fi54gadWdh4Ed2e2O%2FMaze-TMT-Request-Mobile-One-Destination%3Fnode-id%3D824%253A2243%26viewport%3D245%252C645%252C0.057924412190914154%26scaling%3Dscale-down&chrome=DOCUMENTATION"
            allowfullscreen
          ></iframe>
        </Box>
      </HeaderPara>
      <CaptionedImage
        imageSrc={tripRequestUI}
        caption={"Key screens from final UI design"}
      />
      <HeaderPara header={"Learnings"}>
        <p>
          <b>
            {
              "Test thoroughly to make sure there are no broken flows before launch. "
            }
          </b>
          {
            "Trying to hack a third-party app into the MVP user flow met with more technical challenges than initially thought. Though the two engineers and I managed to fix the bot launching issues in the end, there was close to a week that the Tailor-made Trip was supposedly launched, with the homepage and How it Works page already updated to reflect that."
          }
        </p>
        <p>
          {"With that being said, "}
          <b>
            {
              "a communicative and collaborative team makes a quick resolution of issues. "
            }
          </b>
          {
            "I have only known the engineers for a short amount of time but they were open to collaboration and willing to go the extra mile to make things work."
          }
        </p>
      </HeaderPara>
      <PageNav
        prevTitle={"Anywhr Redesign"}
        prevPath={"/2019/anywhr-web-app-redesign"}
        nextTitle={"Anywhr Brand Refresh"}
        nextPath={"/2020/anywhr-brand-refresh"}
      />
    </ContentTemplate>
  </Layout>
)

export default AnywhrTailorMadeTripsPage
